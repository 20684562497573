<template>
    <v-layout row wrap justify-center align-center pt-2 class="home-container container">
      <v-flex xs12>
        <v-progress-linear v-if="!bShowBar" indeterminate color="primary"></v-progress-linear>  
      </v-flex>
      <v-flex xs12 mt-2>
          <p class="text-primary text-medium font-size-3 text-center">{{ sTitle }}</p>
      </v-flex>
      <v-flex xs12 sm6 md6 lg5 mt-1 v-for="(itemDash, index) in itemsDash"
        v-show="itemDash.children.length"
        :key="itemDash.idInformacionGeneralDash" 
        class="item-container"
        :class="'dura-'+index"
        row wrap>
        <v-card v-if="itemDash.acceso=='1'" 
        elevation="4"  width="100%" 
        class="pa-3 card">
          <v-layout row>
          <v-flex xs12 sm12 md12 lg12>
            <div class="item-icon animated bounceIn" :class="'dura-'+(index+1)">
              <v-icon>{{itemDash.Imagen}}</v-icon>
            </div>
          </v-flex>
          <v-flex xs12 pb-3 class="cont-text animated fadeInUp d-flex row wrap justify-center align-center" :class="'dura-'+index">
            <v-flex xs12>
              <div class="text-medium text-primary font-size-1 text-primary my-4" v-html="itemDash.Titulo"></div>
            </v-flex>
            <v-flex xs6 sm12 md6 v-for="(child) in itemDash.children"  
              :key="child.idSubInforGeneralDash" dark px-1 py-1
              >
              <div color="primary" class="item-button" @click="changeTo(child.Link)">
                  <p class="">{{ child.SubInformacion }}</p>
                  <p class="font-weight-black headline">{{ child.Cantidad }}</p>
              </div>
            </v-flex>
          </v-flex>
            
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex v-if="bSinPermisos" xs12 mt-2>
        <v-layout fluid row  wrap justify-center align-center class="no-acceso">
          <v-flex xs12 sm6 v-for="lock in 4" :key="lock" class="pa-3">
            <v-skeleton-loader
              :loading="false"
              class="mx-auto"
              max-width="100%"
              type="list-item-avatar-three-line"
            >
            </v-skeleton-loader>
          </v-flex>
          <div class="mensaje">
            <div class="cont-icon">
              <v-icon size="40px" class="icon">mdi-lock-off-outline</v-icon>
            </div>
            <div class="texto-mensaje pa-3">
              <span class="text-primary font-size-1">
                No posee permisos sobre la dashboard
              </span>
            </div>
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
</template>
<script>
import axiosServices from "@/services/axiosServices.js";
export default {
  name: "DashConfig",
  props: {
    sIdModule: {
      type: String
    }
  },
  data() {
    return {
      itemsDash: [],
      bShowBar: false,
      sTitle: "Cargando...",
      bSinPermisos: false
    };
  },
  mounted() {
    this.getCards();
  },
  methods: {
    changeTo(sRouter) {
      // console.log(sRouter)
      this.$router.replace({
        path: sRouter
      });
    },
    getCards() {
      axiosServices
        .getConfiguration("/infodash", true, `nIdModule=${this.sIdModule}&nIdPerfil=${this.$store.state.idPerfil}`)
        .then(aGetCards => {
          if (Array.isArray(aGetCards.aData) && aGetCards.aData.length != 0) {
            this.bShowBar = true;
            this.sTitle = "Información Inicial";
            this.itemsDash = aGetCards.aData;
            let nContadorPermisos = 0
            this.itemsDash.forEach (item =>{
              if (item.acceso=='0') nContadorPermisos++
            })
            if(nContadorPermisos == this.itemsDash.length) this.bSinPermisos = true
          } else {
            this.bShowBar = true;
            this.sTitle = "No hay datos.";
          }
        });
    }
  },
  watch: {
    sIdModule(sNewValue, sOldValue) {
      if (sNewValue !== sOldValue) {
        this.itemsDash = [];
        this.bShowBar = false;
        this.sTitle = "Cargando...";
        this.getCards();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.v-application .headline {
  font-family: "Muli" !important;
}
.v-application .display-1 {
  font-family: "Muli" !important;
}
.card{
  border-radius: 14px!important;
  min-height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-container{
  width: 100%;
  margin: 0!important;
  padding: 0;
  padding-bottom: 80px;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}
.item-container{
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content:center;
  margin: 0 auto;
  padding: 5px;
  .item-icon{
    background: linear-gradient(0deg, rgba(218,218,218,1) 0%, rgba(238,238,238,1) 100%);
    width: 80px;
    height: 80px;
    border: solid #FFF 1px;
    box-shadow: 0 0 10px 3px rgba($color: #000000, $alpha: .2);
    margin: 0 auto;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    i{
    font-size: 55px;
    color:var(--v-primary-base);
    }
  }
  .cont-text{
    .item-button{
      width:0 auto;
      padding: 2px;
      border-radius: 4px;
      background: var(--v-primary-base);
      border: solid 2px var(--v-primary-base);
      color: #FFFFFF;
      line-height: 1.3em;
      font-size: 0.8em;
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover{
        background-color:#FFF;
        color: var(--v-primary-base);
      }
      p{
        margin: 0;
        padding: 0;
      }
    }
  }
}
  .container {
    padding: 0px 5px!important;
  }
  .no-acceso{
    margin: 0;
    position: relative;
    text-align: center;
    height: 40vh;
  .mensaje{
      position: absolute;
      margin: 0 auto;
      top: 20%;
      width: 100%;
    }

    .cont-icon{
      background: linear-gradient(0deg, rgba(218,218,218,1) 0%, rgba(238,238,238,1) 100%);
      width: 80px;
      height: 80px;
      border: solid #FFF 1px;
      box-shadow: 0 0 10px 3px rgba($color: #000000, $alpha: .2);
      margin: 0 auto;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      i{
      font-size: 55px;
      color:var(--v-primary-base);
      }
    }
    .texto-mensaje{
      border-radius: 10px;
      border: solid #FFF 1px;
      margin-top:5px;
      background: linear-gradient(0deg, rgba(218,218,218,0.6) 0%, rgba(238,238,238,0.6) 100%);      
    }
    }
</style>