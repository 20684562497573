<template>
    <v-menu
        v-menu="bMenu"
        offset-y
        transition="slide-x-transition"
        :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon color="primary" v-on="on"
          @mouseover="omMouseOverMenu" 
          ></v-app-bar-nav-icon>
        </template>
        <v-list
          v-if="aMenu.length>0"
          dense>
            <template  v-for="itemMenu in aMenu">
                <v-hover v-slot:default="{ hover }" :key="itemMenu.idMenuModulo">
                    <v-list-group
                    :value="hover"
                    :prepend-icon="itemMenu.Icono"
                    class="list-main"
                    no-action>
                        
                    <template v-slot:activator>
                        <v-list-item link>
                            <v-list-item-content
                                @click="onIr(itemMenu.Ruta,itemMenu.acceso)"
                            >
                                <v-list-item-title  v-text="itemMenu.Menu" />
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-divider></v-divider>
                    <v-container>
                        <v-row dense align="center" justify="center">
                        <v-btn
                            class="font-weight-black my-2"
                            color="titlesdark"
                            dark>
                            Importar
                        </v-btn>
                        </v-row>
                    </v-container>
                    <v-divider></v-divider>
                    <template v-for="(child) in itemMenu.Hijos">
                        <v-menu
                        v-if="child.Hijos.length>0"
                        v-model="child.bHover"
                        :key="child.idSubItemMenu"
                        :to="child.SubTo"
                        open-on-hover
                        nudge-top="60"
                        offset-x
                        offset-y
                        transition="slide-x-transition">
                        <template v-slot:activator="{ on }">
                            <v-row color="titlesdark" v-on="on" @click="onIr(child.SubTo,child.acceso)">
                                <v-col
                                    cols="3"
                                >
                                    <v-icon
                                    size="30px"
                                    color="primary"
                                    >
                                        {{child.Icon}}
                                    </v-icon>
                                </v-col>
                                <v-col
                                    cols="9"
                                >
                                    {{child.SubItem}}
                                </v-col>
                                    <v-col
                                    cols="3"
                                >
                                    <v-icon
                                        v-if="child.bHover"
                                        size="30px"
                                    >
                                        fas fa-chevron-right
                                    </v-icon>
                                    <v-icon
                                        v-else
                                        size="30px"
                                    >
                                        fas fa-chevron-left
                                    </v-icon>
                                </v-col>
                                
                            </v-row>
                        </template>
                        <v-list
                            dense>
                            <v-list-group
                                v-for="itemMenu2 in child.Hijos"
                                :key="itemMenu2.idMenuModulo"
                                :prepend-icon="itemMenu2.Icon"
                                class="list-main"
                                disabled
                                sub-group
                                no-action>
                                <template v-slot:activator>
                                <v-list-item link>
                                    <v-list-item-content
                                        @mouseover="onMouseAction(itemMenu2,'Over')"
                                        @click="onIr(itemMenu2.SubTo,itemMenu2.acceso)"
                                    >
                                    <v-list-item-title 
                                            v-text="itemMenu2.SubItem"
                                        />
                                    </v-list-item-content>
                                </v-list-item>
                                </template>
                            </v-list-group>
                        </v-list>
                        </v-menu>
                        <v-row 
                            v-else 
                            :key="child.idSubItemMenu"
                            @click="onIr(child.SubTo, child.acceso)"
                            color="titlesdark"
                            class="hv"
                        >
                            <v-col
                                cols="3"
                            >
                                <v-icon
                                size="25px"
                                color="primary"
                                >
                                    {{child.Icon}}
                                </v-icon>
                            </v-col>
                            <v-col
                                cols="9"
                            >
                                {{child.SubItem}}
                            </v-col>
                        </v-row>
                    </template>
                    
                    </v-list-group>
                </v-hover>
            </template>
        </v-list>
        <v-list
          v-else
          dense>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title  v-text="'Su perfil no cuenta con permisos en el menú'" />
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <modal-mensaje
        v-model="bModalPermisos"
        sMensaje="No tiene permisos para ver este menú"
        :nTipo="2"
        />
    </v-menu>
</template>
<script>
 import ModalMensaje from '@/components/ModalMensaje.vue'
export default {
    name:'MenuPrincipal',
    props: {
        aMenu: {
            type: Array,
        },
        nNivel:{
            type: Number,
            Default: 1
        }
    },
    components: {
        ModalMensaje
    },
    data() {
        return {
            nNivelActual: 0,
            nSubNivel: 0,
            bMenu: false,
            bModalPermisos: false
        }
    },
    mounted() {
        this.nNivelActual = this.nNivel
        this.nSubNivel = this.nNivelActual+1
    },
    methods: {
        onMouseAction(oItem,sAccion) {
            sAccion
            oItem.bMostrar= true
        },
        onIr(sRuta,sPermiso){
            //console.log(sPermiso)
            if(sRuta != '' && sPermiso=='1'){
                this.$router.push({ path: sRuta})
                this.bMenu= false
            }
            if(sPermiso=='0'){
                this.bModalPermisos = true
                this.bMenu= false
            }
        },
        omMouseOverMenu(){
            this.bMenu = true
        }
    }
}
</script>
<style lang="scss" scoped>
.hv{
    cursor: pointer;
}
.hv:hover{
    background: rgba($color: #0E69B0, $alpha: .2)
}

</style>
