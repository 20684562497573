<template>
  <NavigationConfig 
    :sIdModule="nIdModule"
    :sTitleMenu="sTitleModule"
  /> 
</template>

<script>
import { mapGetters } from 'vuex';
import NavigationConfig from "@/components/NavigationConfig.vue"
export default {
  name:"menuModulo",
  props: {
    nId: {
      type: String,
    },
    sTitle: {
      type: String,
    }
  },
  components: {
    NavigationConfig
  },
  data() {
    return {
    }
  },
  computed: {
    nIdModule() {
      let nIdModule = this.getIdModule;
      return nIdModule;
    },
    sTitleModule() {
      let sTitleModule = this.getTitleModule;
      return sTitleModule;
    },
    ...mapGetters(['getIdModule', 'getTitleModule'])
  },
}
</script>
