<template>
  <div>
    <v-app-bar
      v-if="this.$route.query.incrustado == undefined"
      flat
      clipped-right
      color="#FFF"
      class="cont-shadown-rounded mb-2"
      height="40px">
      <menu-principal
        :aMenu="itemsMenu"
        :nNivel="1"
      />
      <v-toolbar-title class="text-primary">{{ sTitleMenu }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn
        :to="'/DashBoard'"
        outlined small icon 
        color="primary" class="mx-1">
        <v-icon size="20px" class="btncolor--text mx-1">mdi-view-dashboard-outline</v-icon>
      </v-btn> -->
      <v-btn v-if="itemsProgress.length>0" outlined small icon class="mx-1"
        color="primary"
        @click="drawerRight = !drawerRight">
        <v-icon size="22px" class="btncolor--text mx-1" :class="{'rotate-icon':drawerRight}">mdi-menu-open</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-if="this.$route.query.incrustado == undefined && itemsProgress.length>0"
      app
      dark
      right
      clipped
      floating
      width="325"
      v-model="drawerRight"
      class="px-3 py-3"
      color="primarydark">
      <v-list
        flat
        disabled
        dense>
        <v-toolbar-title class="text-medium text-white text-max mb-2">
          Avance de configuración
        </v-toolbar-title>
        <v-divider></v-divider>
        <v-list-item-group
          v-for="itemProgress in itemsProgress"
          :key="itemProgress.title"
          color="white">
          <v-list-item color="white">
            <v-list-item-icon>
              <v-icon color="white">{{ (itemProgress.complete == 0) ? 'far fa-circle' : 'fa-circle' }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-medium text-white">{{ itemProgress.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item color="white">
          <v-progress-linear
            background-opacity="0.05"
            buffer-value="100"
            :value="sValueItems"
            color="primary"
            rounded
            striped
            height="30">
            <strong>{{ sValueItems }}%</strong>
          </v-progress-linear>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-container>
      <!-- <dashConfig v-if="(/^\/Inicio\/[a-zA-Zá-źÁ-Ź0-9%]+$/.test($route.path))" :sIdModule="sIdModule"></dashConfig> -->
      <dashConfig v-if="!(/^\/Inicio\/.+\//.test($route.path))" :sIdModule="sIdModule"></dashConfig>
      <router-view name="config"></router-view>
    </v-container>
  </div>
</template>
<script>
import axiosServices from "@/services/axiosServices.js"
import dashConfig from '@/components/DashConfig.vue'
import MenuPrincipal from '@/components/Menu.vue'

export default {
  name:"navconfig",
  props: {
    sIdModule: {
      type: String,
    },
    sTitleMenu: {
      type: String,
    }
  },
  components: {
    dashConfig,
    MenuPrincipal
  },
  data() {
    return {
      drawerRight: false,
      itemsMenu: [],
      itemsProgress: [],
      sValueItems: 0,
    }
  },
  mounted() {
    if(this.$route.query.incrustado == undefined){
      this.getProgress();
      this.getMenuModules();
    }
  },
  methods: {
    getMenuModules() {
      axiosServices.getConfiguration("/modulesMenu", true, `nIdModule=${this.sIdModule}&nIdPerfil=${this.$store.state.idPerfil}`)
      .then(aGetMenuModules => {
        this.itemsMenu = aGetMenuModules.aData;
      })
    },
    getProgress() {
      axiosServices.getConfiguration('/progress', true, `nIdModule=${this.sIdModule}`)
      .then(aGetProgress => {
        this.itemsProgress = aGetProgress.aData;
        this.getValue(this.itemsProgress);
      });
      
    },
    getValue(oProgress) {
      let nTotalProgress = 0;
      let nCantItems = oProgress.length;
      oProgress.forEach(elemen => {
        if (elemen.complete == 1) {
          nTotalProgress += (100 / nCantItems);
        }
      });
      this.sValueItems = nTotalProgress;
    }
  },
  watch: {
    drawerRight(bNewValue) {
      if (bNewValue) {
        this.getProgress();
      }
    },
    sIdModule(sNewValue, sOldValue) {
      if (sNewValue !== sOldValue) {
        this.getProgress();
        this.getMenuModules();
      }
    }
  }
}
</script>
<style lang="scss">

.titulo-avance{
  background: var(--v-primarydark-base);
  padding:5px;
}
</style>